<template>
  <div class="grid select-none" @click="$emit('click', $event)">
    <div class="call-grid calls" :class="hasNotes ? 'calls--narrow' : ''">
      <Call
        v-for="(call, index) in calls"
        :key="index"
        :call="call"
        :relations="relations"
        :displayConfig="displayConfig.calls"
        @close="$emit('close', $event)"
      />
    </div>
    <div v-if="presences.length" class="call-grid presences">
      <Call
        v-for="call in presences"
        :key="call.id"
        :showTime="false"
        :relations="relations"
        :call="call"
        :displayConfig="displayConfig.presences"
        @close="$emit('close', $event)"
      />
    </div>
  </div>
</template>

<script lang="ts">
  import { Call as GqlCall } from '@/common/graphql/types'
  import { CallDisplayRelations, defaultDisplayConfig, LayoutDisplayConfig } from '@/modules/display/composables/Calls.api'
  import { defineComponent, PropType } from 'vue'
  import Call from './../Call.vue'

  export default defineComponent({
    name: 'GridLayout',
    components: { Call },
    props: {
      calls: {
        type: Array as PropType<GqlCall[]>,
        default: () => ([])
      },
      presences: {
        type: Array as PropType<GqlCall[]>,
        default: () => ([])
      },
      displayConfig: {
        type: Object as PropType<LayoutDisplayConfig>,
        default: () => defaultDisplayConfig
      },
      relations: {
        type: Object as PropType<CallDisplayRelations>,
        default: () => ({})
      },
      hasNotes: {
        type: Boolean,
        default: () => false
      },
      fontSize: {
        type: String,
        default: () => 'normal'
      }
    },
    emits: ['close', 'click']
  })
</script>

<style lang="stylus" scoped>
.grid
  display flex
  height 100%
  margin 0 -10px
  z-index 30
  position relative

  > div
    margin 0 10px

.call-grid
  display grid
  grid-gap 10px
  z-index 10

.calls
  flex 1 1 auto
  grid-template-columns repeat(3, minmax(calc(100% / 4 - 10px), 1fr))
  grid-auto-rows min-content

  &--narrow
    grid-template-columns repeat(2, minmax(calc(100% / 4 - 10px), 1fr))

.presences
  flex 0 0 300px
  grid-template-rows repeat(6, minmax(min-content, max-content))
  grid-template-columns repeat(auto-fill, minmax(190px, 1fr))
  grid-auto-rows min-content
  grid-auto-flow column

  .call
    min-width 190px
</style>