import NotFound from '@/common/components/NotFound.vue'
import MainLayout from '@/common/layouts/MainLayout.vue'
import DisplayView from '@/modules/display/DisplayView.vue'
import SetupView from '@/modules/setup/SetupView.vue'
import AccessDeniedView from '@/modules/user/AccessDeniedView.vue'
import LoginView from '@/modules/user/LoginView.vue'
import { RouteLocation, RouteRecordRaw, RouterView } from 'vue-router'
import { Store } from 'vuex'
import { State } from '@/store'
import DemoView from '@/modules/demo/DemoView.vue'
import { routeToDisplayParams } from '@/modules/display/composables/Calls.api'

export const routes = (store: Store<State>): RouteRecordRaw[] => ([
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: { authRequired: false },
  },
  {
    path: '/setup',
    name: 'setup',
    component: SetupView,
    meta: { authRequired: false },
  },
  {
    path: '/demo',
    name: 'demo',
    component: DemoView,
    meta: { authRequired: false },
  },
  {
    path: '/',
    name: 'main-layout',
    redirect: { name: 'homepage' },
    component: MainLayout,
    children: [
      {
        path: '/home',
        name: 'homepage',
        redirect: () => {
          if (!store.state.user.user.id) {
            return { name: 'login' }
          }

          if (store.state.user.user.homepage) {
            return { name: store.state.user.user.homepage }
          }

          // Redirect to the first route that permissions are available for.
          switch (true) {
          case store.getters['user/can']('systemtree::use_module'):
          case store.getters['user/can']('systemtree::see_display_group_overrides'):
            return { name: 'treeView' }
          case store.getters['user/can']('analytics::use_module'):
            return { name: 'analytics' }
          case store.getters['user/can']('calllog::use_module'):
            return { name: 'log' }
          default:
            return { name: 'display' }
          }
        }
      },
      {
        path: '/no-access',
        name: 'accessDenied',
        component: AccessDeniedView,
        meta: { authRequired: false },
      },
      {
        path: '/display',
        name: 'display',
        meta: { authRequired: false },
        component: DisplayView,
        props: (route: RouteLocation) => {
          return routeToDisplayParams(route)
        },
        children: [
          {
            path: '/display/call/:id?',
            name: 'displayCallForm',
            meta: { isModal: true },
            component: () => import('@/modules/calllog/CallFormView.vue'),
          }
        ]
      },
      {
        path: '/tree',
        name: 'treeView',
        component: () => import('@/modules/device/SystemTreeView.vue'),
        meta: {
          redirectToFirstAvailableChild: true,
        },
        children: [
          {
            path: '/tree/view/:viewId?',
            name: 'tree',
            meta: { permissions: ['systemtree::use_module'] },
            component: () => import('@/modules/device/SystemTreeNodeListView.vue'),
            children: [
              {
                path: '/tree/edit/:id?',
                name: 'systemTreeNodeForm',
                component: () => import('@/modules/device/SystemTreeNodeFormView.vue'),
                meta: { isModal: true },
              },
              {
                path: '/tree/edit/:nodeid/flightprofiles/:id?',
                name: 'systemTreeFlightProfileForm',
                meta: { permissions: ['admin::manage_system'], isModal: true },
                component: () => import('@/modules/admin/flightprofile/FlightProfileFormView.vue'),
              },
              {
                path: '/tree/edit/:nodeid/calls/:id?',
                name: 'systemTreeNodeCallForm',
                meta: { isModal: true },
                component: () => import('@/modules/calllog/CallFormView.vue'),
              },
              {
                path: '/tree/sort',
                name: 'systemTreeSorter',
                meta: { isModal: true },
                component: () => import('@/modules/device/SystemTreeSortView.vue'),
              },
            ]
          },
          {
            path: '/tree/connections',
            name: 'systemTreeConnections',
            meta: { permissions: ['systemtree::manage_devices'] },
            component: () => import('@/modules/device/SystemTreeConnectionsView.vue'),
            children: [
              {
                path: '/tree/connections/edit/:id?',
                name: 'systemTreeConnectionNodeForm',
                component: () => import('@/modules/device/SystemTreeNodeFormView.vue'),
                meta: { isModal: true },
              },
            ],
          },
          {
            path: '/tree/displaygroupoverrides',
            name: 'displayGroupOverrideList',
            meta: { permissions: ['systemtree::see_display_group_overrides'] },
            component: () => import('@/modules/device/components/displaygroupoverride/DisplayGroupOverrideList.vue'),
            children: [
              {
                path: '/tree/displaygroupoverrides/edit/:id?',
                name: 'displayGroupOverrideForm',
                component: () => import('@/modules/device/components/displaygroupoverride/DisplayGroupOverrideFormView.vue'),
                meta: { isModal: true, permissions: ['systemtree::manage_display_group_overrides'] },
              }
            ]
          },
          // Legacy route before custom views were added.
          { path: '/tree/overview', redirect: '/tree' },
        ]
      },
      {
        path: '/log',
        name: 'log',
        meta: { permissions: ['calllog::use_module'] },
        component: () => import('@/modules/calllog/CallLogView.vue'),
        children: [
          {
            path: '/log/edit/:id?',
            name: 'callForm',
            meta: { isModal: true },
            component: () => import('@/modules/calllog/CallFormView.vue'),
          }
        ]
      },
      {
        path: '/analytics',
        name: 'analytics',
        meta: { permissions: ['analytics::use_module'], license: ['analytics'] },
        component: () => import('@/modules/analytics/AnalyticsOverviewView.vue'),
        children: [
          {
            path: '/analytics/systemtree/edit/:id?',
            name: 'analyticsSystemTreeNodeForm',
            meta: { isModal: true },
            component: () => import('@/modules/device/SystemTreeNodeFormView.vue'),
          },
          {
            path: '/analytics/call/edit/:id?',
            name: 'analyticsCallForm',
            meta: { isModal: true },
            component: () => import('@/modules/calllog/CallFormView.vue'),
          }
        ]
      },
      {
        path: '/sync',
        name: 'sync',
        meta: { permissions: ['systemtree::sync'] },
        component: () => import('@/modules/device/SyncView.vue'),
      },
      {
        path: '/appmanagement',
        name: 'appManagement',
        meta: { permissions: ['app::use_module'], license: ['app'], redirectToFirstAvailableChild: true },
        component: () => import('@/modules/app/AppManagementView.vue'),
        children: [
          {
            path: '/appmanagement/subscriptions',
            name: 'appManagementSubscriptions',
            meta: { permissions: ['app::manage_subscriptions'], if: () => store.state.app.app_settings.subscriptions_enabled },
            component: () => import('@/modules/app/appsubscriptions/AppSubscriptionsView.vue'),
          },
          {
            path: '/appmanagement/statistic',
            name: 'appManagementStatistic',
            meta: { permissions: ['app::see_statistic'] },
            component: () => import('@/modules/app/appstatistic/AppStatisticView.vue'),
          },
          {
            path: '/appmanagement/groups',
            name: 'appManagementUserGroupList',
            component: () => import('@/modules/app/appusergroup/AppUserGroupListView.vue'),
            meta: { permissions: ['app::manage_user_groups'] },
            children: [
              {
                path: '/appmanagement/groups/edit/:id?',
                name: 'appManagementUserGroupForm',
                meta: { isModal: true },
                component: () => import('@/modules/app/appusergroup/AppUserGroupFormView.vue'),
                children: [
                  {
                    path: '/appmanagement/groups/edit/:groupid/displayGroups/:id?',
                    name: 'appManagementUserGroupDisplayGroupForm',
                    meta: { isModal: true },
                    component: () => import('@/modules/app/appdisplaygroup/AppDisplayGroupFormView.vue'),
                  },
                ]
              }
            ]
          },
          {
            path: '/appmanagement/users',
            name: 'appManagementUserList',
            component: () => import('@/modules/app/appuser/AppUserListView.vue'),
            meta: { permissions: ['app::manage_users'] },
            children: [
              {
                path: '/appmanagement/users/edit/:id?',
                name: 'appManagementUserForm',
                meta: { isModal: true },
                component: () => import('@/modules/app/appuser/AppUserFormView.vue'),
              }
            ]
          },
          {
            path: '/appmanagement/clients',
            name: 'appManagementClientList',
            meta: { permissions: ['app::manage_devices'] },
            component: () => import('@/modules/app/appclient/AppClientListView.vue'),
            children: [
              {
                path: '/appmanagement/clients/edit/:id',
                name: 'appManagementClientForm',
                meta: { isModal: true },
                component: () => import('@/modules/app/appclient/AppClientFormView.vue'),
              }
            ]
          },
          {
            path: '/appmanagement/displaygroups',
            name: 'appManagementDisplayGroupList',
            component: () => import('@/modules/app/appdisplaygroup/AppDisplayGroupListView.vue'),
            meta: { permissions: ['app::manage_display_groups'] },
            children: [
              {
                path: '/appmanagement/displaygroups/edit/:id?',
                name: 'appManagementDisplayGroupForm',
                meta: { isModal: true },
                component: () => import('@/modules/app/appdisplaygroup/AppDisplayGroupFormView.vue'),
              }
            ]
          },
          {
            path: '/appmanagement/settings',
            name: 'appManagementSettings',
            meta: { permissions: ['app::manage_settings'] },
            component: () => import('@/modules/app/appsettings/AppSettingsView.vue'),
          },
        ]
      },
      {
        path: '/admin',
        name: 'admin',
        meta: { redirectToFirstAvailableChild: true, permissions: ['admin::use_module'] },
        component: RouterView,
        children: [
          {
            path: '/admin/systemparams',
            name: 'systemParamList',
            meta: { permissions: ['admin::manage_system'] },
            component: () => import('@/modules/admin/systemparam/SystemParamListView.vue'),
            children: [
              {
                path: '/admin/systemparams/edit/:id?',
                name: 'systemParamForm',
                meta: { isModal: true },
                component: () => import('@/modules/admin/systemparam/SystemParamFormView.vue'),
              },
            ]
          },
          {
            path: '/admin/calltypes',
            name: 'callTypeList',
            meta: { permissions: ['admin::manage_system'] },
            component: () => import('@/modules/admin/calltype/CallTypeListView.vue'),
            children: [
              {
                path: '/admin/calltypes/edit/:id?',
                name: 'callTypeForm',
                meta: { isModal: true },
                component: () => import('@/modules/admin/calltype/CallTypeFormView.vue'),
              },
              {
                path: '/admin/calltypes/sort',
                name: 'callTypeSorter',
                meta: { isModal: true },
                component: () => import('@/modules/admin/calltype/CallTypeSortView.vue'),
              },
            ]
          },
          {
            path: '/admin/schedules',
            name: 'scheduleList',
            meta: { permissions: ['admin::manage_system'] },
            component: () => import('@/modules/admin/schedule/ScheduleListView.vue'),
            children: [
              {
                path: '/admin/schedules/edit/:id?',
                name: 'scheduleForm',
                meta: { isModal: true },
                component: () => import('@/modules/admin/schedule/ScheduleFormView.vue'),
              },
            ]
          },
          {
            path: '/admin/sections',
            name: 'sectionList',
            meta: { permissions: ['admin::manage_system'] },
            component: () => import('@/modules/admin/section/SectionListView.vue'),
            children: [
              {
                path: '/admin/sections/edit/:id?',
                name: 'sectionForm',
                meta: { isModal: true },
                component: () => import('@/modules/admin/section/SectionFormView.vue'),
              },
            ]
          },
          {
            path: '/admin/displaygroups',
            name: 'displayGroupList',
            meta: { permissions: ['admin::manage_system'] },
            component: () => import('@/modules/admin/displaygroup/DisplayGroupListView.vue'),
            children: [
              {
                path: '/admin/displaygroups/edit/:id?',
                name: 'displayGroupForm',
                meta: { isModal: true },
                component: () => import('@/modules/admin/displaygroup/DisplayGroupFormView.vue'),
              },
            ]
          },
          {
            path: '/admin/checkpointmaps',
            name: 'checkpointMapList',
            meta: { permissions: ['admin::manage_system'] },
            component: () => import('@/modules/admin/checkpointmap/CheckpointMapListView.vue'),
            children: [
              {
                path: '/admin/checkpointmaps/edit/:id?',
                name: 'checkpointMapForm',
                meta: { isModal: true },
                component: () => import('@/modules/admin/checkpointmap/CheckpointMapFormView.vue'),
              },
              {
                path: '/admin/checkpointmaps/sort',
                name: 'checkpointMapSorter',
                meta: { isModal: true },
                component: () => import('@/modules/admin/checkpointmap/CheckpointMapSortView.vue'),
              },
            ]
          },
          {
            path: '/admin/flightprofiles',
            name: 'flightProfileList',
            meta: { permissions: ['admin::manage_flightprofiles'] },
            component: () => import('@/modules/admin/flightprofile/FlightProfileListView.vue'),
            children: [
              {
                path: '/admin/flightprofiles/edit/:id?',
                name: 'flightProfileForm',
                meta: { isModal: true },
                component: () => import('@/modules/admin/flightprofile/FlightProfileFormView.vue'),
              },
            ]
          },
          {
            path: '/admin/mergegroups',
            name: 'mergeGroupList',
            meta: { permissions: ['admin::manage_system'] },
            component: () => import('@/modules/admin/mergegroup/MergeGroupList.vue'),
            children: [
              {
                path: '/admin/mergegroups/edit/:id?',
                name: 'mergeGroupForm',
                component: () => import('@/modules/admin/mergegroup/MergeGroupFormView.vue'),
                meta: { isModal: true },
              }
            ]
          },
          {
            path: '/admin/callsources',
            name: 'callSourceList',
            meta: { permissions: ['admin::manage_system'] },
            component: () => import('@/modules/admin/callsource/CallSourceListView.vue'),
            children: [
              {
                path: '/admin/callsources/edit/:id?',
                name: 'callSourceForm',
                meta: { isModal: true },
                component: () => import('@/modules/admin/callsource/CallSourceFormView.vue'),
              },
            ]
          },
          {
            path: '/admin/indicators',
            name: 'indicatorList',
            meta: { permissions: ['admin::manage_system'] },
            component: () => import('@/modules/admin/indicator/IndicatorListView.vue'),
            children: [
              {
                path: '/admin/indicators/edit/:id?',
                name: 'indicatorForm',
                meta: { isModal: true },
                component: () => import('@/modules/admin/indicator/IndicatorFormView.vue'),
              },
            ]
          },
          {
            path: '/admin/buttons',
            name: 'buttonList',
            meta: { permissions: ['admin::manage_system'] },
            component: () => import('@/modules/admin/button/ButtonListView.vue'),
            children: [
              {
                path: '/admin/buttons/edit/:id?',
                name: 'buttonForm',
                meta: { isModal: true },
                component: () => import('@/modules/admin/button/ButtonFormView.vue'),
              },
            ]
          },
          {
            path: '/admin/hubs',
            name: 'hubList',
            meta: { permissions: ['admin::manage_system'] },
            component: () => import('@/modules/admin/hub/HubListView.vue'),
            children: [
              {
                path: '/admin/hubs/edit/:id?',
                name: 'hubForm',
                meta: { isModal: true },
                component: () => import('@/modules/admin/hub/HubFormView.vue'),
              },
            ]
          },
          {
            path: '/admin/users',
            name: 'userList',
            meta: { permissions: ['admin::manage_users'] },
            component: () => import('@/modules/admin/user/UserListView.vue'),
            children: [
              {
                path: '/admin/users/edit/:id?',
                name: 'userForm',
                meta: { isModal: true },
                component: () => import('@/modules/admin/user/UserFormView.vue'),
              },
            ]
          },
          {
            path: '/admin/roles',
            name: 'roleList',
            meta: { permissions: ['admin::manage_roles'] },
            component: () => import('@/modules/admin/role/RoleListView.vue'),
            children: [
              {
                path: '/admin/roles/edit/:id?',
                name: 'roleForm',
                meta: { isModal: true },
                component: () => import('@/modules/admin/role/RoleFormView.vue'),
              },
            ]
          },
          {
            path: '/admin/displaynotes',
            name: 'displayNoteList',
            meta: { permissions: ['admin::manage_display_notes'] },
            component: () => import('@/modules/admin/displaynote/DisplayNoteListView.vue'),
            children: [
              {
                path: '/admin/displaynotes/edit/:id?',
                name: 'displayNoteForm',
                meta: { isModal: true },
                component: () => import('@/modules/admin/displaynote/DisplayNoteFormView.vue'),
              },
              {
                path: '/admin/displaynotes/sort',
                name: 'displayNoteSorter',
                meta: { isModal: true },
                component: () => import('@/modules/admin/displaynote/DisplayNoteSortView.vue'),
              },
            ]
          },
          {
            path: '/admin/videostreams',
            name: 'videoStreamList',
            meta: { permissions: ['admin::manage_system'] },
            component: () => import('@/modules/admin/videostream/VideoStreamListView.vue'),
            children: [
              {
                path: '/admin/videostreams/edit/:id?',
                name: 'videoStreamForm',
                meta: { isModal: true },
                component: () => import('@/modules/admin/videostream/VideoStreamFormView.vue'),
              },
            ]
          },
          {
            path: '/admin/alert-channels',
            name: 'alertChannelList',
            meta: { permissions: ['admin::manage_system'] },
            component: () => import('@/modules/admin/alert/AlertChannelListView.vue'),
            children: [
              {
                path: '/admin/alert-channels/edit/:id?',
                name: 'alertChannelForm',
                meta: { isModal: true },
                component: () => import('@/modules/admin/alert/AlertChannelFormView.vue'),
              },
            ]
          },
          {
            path: '/admin/ingress',
            name: 'ingressList',
            meta: { permissions: ['admin::manage_system'] },
            component: () => import('@/modules/admin/ingress/IngressListView.vue'),
            children: [
              {
                path: '/admin/ingress/edit/:id?',
                name: 'ingressForm',
                meta: { isModal: true },
                component: () => import('@/modules/admin/ingress/IngressFormView.vue'),
              },
            ]
          },
          {
            path: '/admin/gets-cloud',
            name: 'getsCloudForm',
            meta: { permissions: ['admin::manage_backups'] },
            component: () => import('@/modules/admin/getscloud/GetsCloudFormView.vue'),
          },
          {
            path: '/admin/backups',
            name: 'backupsView',
            meta: { permissions: ['admin::manage_backups'], license: ['management'] },
            component: () => import('@/modules/admin/backups/BackupsView.vue'),
          },
          {
            path: '/admin/ldap',
            name: 'ldapView',
            meta: { permissions: ['admin::manage_system'], license: ['management'] },
            component: () => import('@/modules/admin/ldap/LDAPFormView.vue'),
          },
          {
            path: '/admin/audit',
            name: 'auditLog',
            meta: { permissions: ['admin::see_auditlog'], license: ['management'] },
            component: () => import('@/modules/admin/auditlog/AuditLogView.vue'),
          },
          {
            path: '/admin/logs',
            name: 'logs',
            meta: { permissions: ['admin::manage_system'], license: ['management'] },
            component: () => import('@/modules/admin/logfile/LogFilesView.vue'),
          },
          {
            path: '/admin/maintenance',
            name: 'systemMaintenance',
            meta: { permissions: ['admin::manage_system'] },
            component: () => import('@/modules/admin/systemmaintenance/SystemMaintenanceView.vue'),
          },
          {
            path: '/admin/defects',
            name: 'defects',
            meta: { permissions: ['admin::manage_system', 'calllog::see_defects'] },
            component: () => import('@/modules/admin/defects/DefectsView.vue'),
          },
          {
            path: '/admin/reports',
            name: 'reportList',
            meta: { permissions: ['admin::manage_reports'], license: ['analytics'] },
            component: () => import('@/modules/analytics/report/ReportListView.vue'),
            children: [
              {
                path: '/admin/reports/edit/:id?',
                name: 'reportForm',
                meta: { isModal: true },
                component: () => import('@/modules/analytics/report/ReportFormView.vue'),
              }
            ]
          },
        ]
      },
      {
        path: '/:catchAll(.*)',
        name: 'not-found',
        component: NotFound
      }
    ]
  }
])
