<template>
  <teleport to="body">
    <transition name="slide">
      <div v-if="isOpen" class="drawer z-40">
        <div class="drawer__overlay" @click="onOverlayClick">
          <div class="drawer__content bg-gray-50 flex flex-col">
            <header class="flex bg-white items-center justify-between">
              <div class="flex-0">
                <a
                  href="#"
                  class="inline-block px-8 py-4 text-2xl cursor-pointer text-center hover:bg-gray-50"
                  @click.prevent="$emit('close')"
                >
                  &larr;
                </a>
              </div>
              <div class="flex-1 text-center">
                {{ $t(title) }}
              </div>
              <div class="flex-0 flex items-center justify-end min-w-[62px]">
                <div class="mr-4 flex actions justify-end items-center">
                  <slot name="actions" />
                </div>
              </div>
            </header>
            <div class="bg-gray-50 relative flex-1">
              <slot />
            </div>
          </div>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'Drawer',
    components: {},
    props: {
      title: {
        type: String,
        default: '',
      },
      isOpen: {
        type: Boolean,
        default: false,
      }
    },
    emits: ['close'],
    setup (props, { emit }) {

      function onOverlayClick (e: MouseEvent) {
        if (e.target instanceof Element && e.target.classList.contains('drawer__overlay')) {
          emit('close')
        }
      }

      return { onOverlayClick }
    }
  })
</script>

<style lang="stylus">
  .drawer
    @apply inset-0 fixed

    &__overlay
      @apply flex-1 opacity-100 z-10 h-full w-full
      background rgba(0, 0, 0, .4)
      transition .15 ease-out opacity

    &__content
      @apply flex-shrink-0 shadow-2xl h-full w-full fixed right-0 z-20 overflow-y-auto
      width 520px
      transform translateX(0)

  .slide-enter-from,
  .slide-leave-to
    .drawer__content
      transform translateX(100px) !important

  .slide-enter-to,
  .slide-leave-from
    .drawer__content
      transform translateX(0) !important

  .drawer__content
    transition transform .15s ease-out
</style>

