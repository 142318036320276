<script setup lang="ts">
  import TimeSince from '@/common/components/TimeSince.vue'
  import { Call, CallTypeFlag } from '@/common/graphql/types'
  import { CallDisplayConfig, CallDisplayRelations, getDisplayValue, useShowCloseAction } from '@/modules/display/composables/Calls.api'
  import { computed, PropType, ref } from 'vue'
  import { useStore } from '@/store'
  import { mdiHumanHandsdown, mdiRun } from '@mdi/js'
  import MdiIcon from '@/common/components/svg/MdiIcon.vue'

  defineEmits(['close'])
  const props = defineProps({
    call: {
      type: Object as PropType<Call>,
      default: () => ({} as Call)
    },
    displayConfig: {
      type: Object as PropType<CallDisplayConfig>,
      default: () => ({} as CallDisplayConfig)
    },
    condensed: {
      type: Boolean,
      default: false
    },
    relations: {
      type: Object as PropType<CallDisplayRelations>,
      default: () => ({})
    },
  })

  const store = useStore()
  const ignore = computed(() => {
    if (props.condensed) {
      return ['call_type_text', 'call_source_text']
    }
    return ['call_type_text']
  })

  const lines = computed(() => props.displayConfig.lines.filter(line => ignore.value.indexOf(line) === -1))
  const bolds = computed(() => props.displayConfig.bolds.filter(line => ignore.value.indexOf(line) === -1))

  const isFullscreen = computed(() => store.state.app.isFullscreen)
  const isPresence = computed(() => {
    return props.call.call_type?.flag === CallTypeFlag.Presence
  })

  const showCloseAction = useShowCloseAction(store, ref(props.call))
</script>

<template>
  <tr class="leading-snug align-middle">
    <td class="pr-4 w-0">
      <div class="px-4 py-1 text-center text-white text-sm relative">
        <div
          class="absolute inset-0 z-0 opacity-10 bg-blue-400 rounded border-l-2"
          :style="{
            'background-color': call.call_type ? call.call_type.color : '',
            'border-color': call.call_type ? call.call_type.color : '',
          }"
        />
        <div
          class="text-blue-800 whitespace-nowrap truncate overflow-hidden"
          :style="{'color': call.call_type ? call.call_type.color : ''}"
        >
          {{ condensed && call[displayConfig.title] ? call[displayConfig.title] : call.call_type_text }}
        </div>
      </div>
    </td>
    <td v-if="!condensed && call[displayConfig.title]" class="ellipsis px-4 py-2 font-semibold">
      <div>
        {{ call[displayConfig.title] }}
      </div>
    </td>
    <td v-for="line in bolds" :key="line" class="ellipsis py-2 px-4 font-semibold">
      <div v-if="getDisplayValue(call, line)">
        {{ getDisplayValue(call, line) }}
      </div>
    </td>
    <td v-for="line in lines" :key="line" class="ellipsis py-2 px-4">
      <div v-if="getDisplayValue(call, line)">
        {{ getDisplayValue(call, line) }}
      </div>
    </td>
    <td v-if="isFullscreen">
      <span v-if="call.handled_by_app_username" class="flex items-center">
        <MdiIcon class="fill-current w-[15px] mr-1" :icon="isPresence ? mdiHumanHandsdown : mdiRun" />
        {{ call.handled_by_app_username }}
      </span>
    </td>
    <td v-if="!condensed" class="w-0 whitespace-nowrap">
      <TimeSince class="text-right whitespace-nowrap" mode="short" :time="call.opened_at" />
    </td>
    <td class="w-0 whitespace-nowrap">
      <div
        v-if="showCloseAction"
        v-tooltip="{ content: $t('call.actions.close_call'), delay: 1500 }"
        class="rounded leading-none py-1.5 px-2 ml-2 text-gray-600 cursor-pointer font-bold text-lg hover:bg-gray-100"
        @click.prevent="$emit('close', call)"
      >
        &times;
      </div>
    </td>
  </tr>
</template>

<style lang="stylus">
  .ellipsis {
    position: relative;
  }

  .ellipsis:before {
    content: '&nbsp;';
    visibility: hidden;
  }

  .ellipsis div {
    @apply px-4
    position: absolute;
    left: 0;
    right: 0;
    top 50%;
    transform: translateY(-50%)
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
