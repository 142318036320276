<script setup lang="ts">
  import IconPlus from '@/common/components/svg/IconPlus.vue'
  import IconTick from '@/common/components/svg/IconTick.vue'
  import IconEdit from '@/common/components/svg/IconEdit.vue'
  import { computed, useSlots } from 'vue'

  const slots = useSlots()

  defineOptions({
    inheritAttrs: false,
  })

  defineEmits(['click'])

  const props = defineProps({
    type: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: undefined,
    },
    submit: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'default'
    },
    loading: {
      type: Boolean,
      default: false
    },
    // TODO: This property should not be required since we check if the content slot is empty below.
    // Unfortunately, since Vue 3 this code does no longer work, that's why this property was added as a quick-and-dirty workaround.
    displayMode: {
      type: String,
      default: 'default',
    },
  })
  const showIcon = computed(() => props.icon || props.displayMode === 'icon' || slots.icon)
  const showContent = computed(() => props.displayMode !== 'icon')
  const buttonClasses = computed(() => {
    const classes = []
    switch (props.type) {
    case 'primary':
      classes.push('shadow-sm border-transparent bg-csBlue-600 text-white hover:bg-csBlue-500 focus:ring-csBlue-800 focus:ring-csBlue-800 active:bg-csBlue-800')
      break
    case 'primary-inverted':
      classes.push('shadow-sm border-csBlue-100 text-white hover:bg-white hover:bg-opacity-5 focus:border-gray-100 active:ring-gray-100')
      break
    case 'secondary':
      classes.push('border-transparent bg-csBlue-100 text-csBlue-700 hover:bg-indigo-50 focus:border-indigo-300 focus:ring-indigo active:bg-indigo-200')
      break
    case 'secondary-inverted':
      classes.push('border-csBlue-200 bg-transparent text-white hover:bg-csBlue-500')
      break
    case 'danger':
      classes.push('shadow-sm border-transparent bg-red-600 text-white hover:bg-red-500 focus:border-red-700 focus:ring-red active:bg-red-700')
      break
    case 'warning':
      classes.push('shadow-sm border-transparent bg-yellow-600 text-white hover:bg-yellow-500 focus:border-yellow-700 focus:ring-orange active:bg-yellow-700')
      break
    case 'success':
      classes.push('shadow-sm border-transparent bg-green-600 text-white hover:bg-green-500 focus:border-green-700 focus:ring-green active:bg-green-700')
      break
    default:
      classes.push('shadow-sm bg-white border-gray-300 text-gray-700 hover:text-gray-500 focus:border-csBlue-500 focus:ring-csBlue-500 active:text-gray-800 active:bg-gray-50')
      break
    }
    switch (props.size) {
    case 'tiny':
      classes.push('px-3 py-1 text-xs leading-4')
      break
    case 'small':
      classes.push('px-3 py-2 text-xs leading-4')
      break
    default:
      classes.push('px-6 py-2 text-sm leading-5')
    }
    if (props.loading) {
      classes.push('pointer-events-none opacity-75')
    }
    if (showIcon.value) {
      classes.push(props.type !== 'default' && props.type !== 'secondary' ? 'pl-15' : 'pl-9')
    }
    if (!showContent.value) {
      classes.push('h-input')
    }
    return classes.join(' ')
  })
  const iconClasses = computed(() => {
    const classes = []
    switch (props.type) {
    case 'primary':
      classes.push('text-white bg-csBlue-800 text-white')
      break
    case 'primary-inverted':
      classes.push('bg-white bg-opacity-10 text-white')
      break
    case 'secondary':
      classes.push('bg-transparent')
      break
    case 'secondary-inverted':
      classes.push('bg-white bg-opacity-10 text-white')
      break
    case 'danger':
      classes.push('text-white bg-red-700')
      break
    case 'warning':
      classes.push('text-white bg-yellow-700')
      break
    case 'success':
      classes.push('text-white bg-green-700')
      break
    default:
      if (showContent.value) {
        classes.push('bg-gray-50')
      }
      break
    }
    if (!showContent.value) {
      classes.push('w-full')
    } else {
      classes.push('w-9')
    }
    return classes.join(' ')
  })
</script>

<template>
  <button
    class="button inline-flex items-center border rounded-md focus:outline-none transition ease-in-out duration-150 relative"
    :class="buttonClasses"
    :type="submit ? 'submit' : 'button'"
    v-bind="$attrs"
    @click.prevent="$emit('click', $event)"
  >
    <span
      v-if="showIcon"
      class="flex items-center justify-center h-full absolute left-0 top-0 rounded-l-md"
      :class="iconClasses"
    >
      <slot name="icon">
        <IconPlus v-if="icon === 'plus'" />
        <IconTick v-else-if="icon === 'tick'" class="w-6" />
        <IconEdit v-else-if="icon === 'edit'" class="w-4" />
        <IconCross v-else-if="icon === 'trash'" class="w-6" />
        <IconRefresh v-else-if="icon === 'refresh'" class="w-5" />
      </slot>
    </span>
    <span
      v-if="showContent"
      class="flex-1 h-full w-full flex items-center justify-center whitespace-nowrap relative"
      :class="{'pl-9': showIcon}"
    >
      <div v-if="loading" class="absolute inset-0">
        <Loader />
      </div>
      <div :class="{'opacity-0': loading}">
        <slot />
      </div>
    </span>
  </button>
</template>

<style lang="stylus" scoped>
  .button
    &[disabled]
      opacity .8
      cursor not-allowed
      filter grayscale(.5)
</style>

<style lang="stylus">
  .button
    .loading-spinner__spinner
      width 20px !important
      height 20px !important
</style>
