<script setup lang="ts">
  import {
    ActiveDisplayNotesQuery,
    Call,
    CallDisplayRelationsQuery,
    CallSource,
    CallTypeFlag,
    DisplayGroup,
    Locale
  } from '@/common/graphql/types'
  import Display from '@/modules/display/components/Display.vue'
  import {
    CallDisplayRelations,
    DisplayParams,
    LayoutDisplayConfig,
    sortCalls
  } from '@/modules/display/composables/Calls.api'
  import { useWakeLock } from '@/modules/display/composables/WakeLock'
  import { useQuery, useSubscription } from '@vue/apollo-composable'
  import { computed, onMounted, onUnmounted, PropType, ref, watch } from 'vue'
  import { useStore } from '@/store'
  import QueryOpenCalls from './graphql/QueryOpenCalls.gql'
  import QueryActiveDisplayNotes from '@/modules/admin/displaynote/graphql/QueryActive.gql'
  import QueryDisplayRelations from './graphql/QueryRelations.gql'
  import CallsAddedSubscription from './graphql/SubscriptionCallsAdded.gql'
  import { useI18n } from 'vue-i18n'
  import { logError } from '@/common/utils'
  import ViewScaffold from '@/common/layouts/main/ViewScaffold.vue'
  import { format, timeToInt, useTime } from '@/common/services/time'
  import CloseCallConfirmationPopup from '@/modules/calllog/components/CloseCallConfirmationPopup.vue'
  import { useAppDataLoader } from '@/common/services/appdata/AppData.api'
  import {
    DisplayGroupConfiguration,
    useDisplayGroupFilterCallTypeMap
  } from '@/modules/admin/displaygroup/DisplayGroup.api'
  import DisplaySettingsDrawer from '@/modules/display/components/DisplaySettingsDrawer.vue'

  const props = defineProps({
    sections: {
      type: Array as PropType<number[]>,
      default: () => ([])
    },
    ignoreSectionsFilterForCallTypes: {
      type: Array as PropType<number[]>,
      default: () => ([])
    },
    ignoreSectionsTimeStart: {
      type: String,
      default: ''
    },
    ignoreSectionsTimeEnd: {
      type: String,
      default: ''
    },
    layout: {
      type: String as PropType<'grid' | 'list'>,
      default: 'grid'
    },
    fullscreen: {
      type: Boolean,
      default: false
    },
    videoStream: {
      type: Boolean,
      default: true
    },
    displayGroupConfig: {
      type: Object as PropType<DisplayGroupConfiguration>,
      default: () => ({})
    },
    locale: {
      type: String as PropType<Locale>,
      default: ''
    },
    system: {
      type: String,
      default: ''
    },
    fontSize: {
      type: String as PropType<'normal' | 'larger' | 'largest'>,
      default: 'normal'
    }
  })

  const store = useStore()
  const i18n = useI18n()
  const allCalls = ref<Call[]>([])

  const settings = ref<DisplayParams>({
    fontSize: props.fontSize,
    fullscreen: props.fullscreen,
    layout: props.layout,
    sections: props.sections,
    system: props.system,
    ignoreSectionsFilterForCallTypes: props.ignoreSectionsFilterForCallTypes,
    ignoreSectionsTimeEnd: props.ignoreSectionsTimeEnd,
    ignoreSectionsTimeStart: props.ignoreSectionsTimeStart,
    displayGroupConfig: props.displayGroupConfig,
    locale: props.locale,
  })

  const settingsDrawerVisible = ref(false)
  const callToBeClosed = ref<Call | null>(null)

  const isFullscreen = computed(() => store.state.app.isFullscreen)
  const isBackendConnected = computed(() => store.state.app.isBackendConnected)
  const currentSystem = computed(() => store.state.app.systems.find(s => s.id === store.state.app.systemContext))

  const time = useTime()
  const { requestWakeLock, releaseWakeLock } = useWakeLock()

  const reloadMilliseconds = 1000 * 60 * 60 * 4 // 4 h

  function keyPressHandler (e: KeyboardEvent) {
    if (e.code === 'Escape') {
      store.commit('app/setIsFullscreen', false)
    }
  }

  const { fetch: fetchAppData } = useAppDataLoader(store)

  async function setSystemContext (systemID: string) {
    await store.dispatch('app/setSystemContext', { systemId: systemID, fetchAppData })

    const params = new URLSearchParams(window.location.search)
    params.delete('system')

    window.history.replaceState({}, '', `${window.location.pathname}?${params.toString()}`)

    document.location.reload()
  }

  let reloadInterval: ReturnType<typeof setInterval>
  onMounted(async () => {
    // If a system parameter is set, reset the system context.
    if (settings.value.system && store.state.app.systemContext !== settings.value.system) {
      await setSystemContext(settings.value.system)
      return
    }

    reloadInterval = setInterval(() => {
      if (isBackendConnected.value) {
        document.location.reload()
      }
    }, reloadMilliseconds)

    if (settings.value.fullscreen) {
      store.commit('app/setIsFullscreen', true)
    }

    document.body.addEventListener('keydown', keyPressHandler)
    await requestWakeLock()
  })

  onUnmounted(() => {
    clearInterval(reloadInterval)
    document.body.removeEventListener('keydown', keyPressHandler)
    releaseWakeLock()
  })

  const {
    result: relationsResult,
    onError: onRelationsError,
    refetch: refetchRelations,
  } = useQuery<CallDisplayRelationsQuery>(QueryDisplayRelations)
  onRelationsError(err => {
    logError('failed to fetch call display relations', err)
    store.commit('notifications/notify', {
      title: i18n.t('call.notifications.fetch_relations_error.title'),
      text: i18n.t('call.notifications.fetch_relations_error.text'),
      type: 'error'
    })
  })

  // When the display config (schedules, display groups) is updated in the Backend, refetch the relations.
  const lastDisplayConfigUpdate = computed(() => store.state.app.lastDisplayConfigUpdate)
  watch(() => currentSystem.value, () => {
    refetchRelations()
  })
  watch(() => lastDisplayConfigUpdate.value, () => {
    refetchRelations()
  })

  const callSources = computed(() => relationsResult.value?.callSources ?? [])
  const schedules = computed(() => relationsResult.value?.schedules ?? [])
  const displayGroups = computed(() => (relationsResult.value?.displayGroups ?? []) as Partial<DisplayGroup>[])

  const relations = computed<CallDisplayRelations>(() => {
    const cs: Record<number, Pick<CallSource, 'id' | 'char' | 'number'>> = {}
    callSources.value?.forEach(value => {
      cs[value.number] = value
    })
    return {
      callSources: cs
    }
  })

  const {
    onResult: onOpenCallsResult,
    loading: openCallsLoading,
    error: openCallsError
  } = useQuery(QueryOpenCalls, {}, { fetchPolicy: 'no-cache' })
  onOpenCallsResult(result => {
    if (!result) {
      return
    }
    if (result.loading) {
      return
    }
    const openCalls = result.data?.openCalls
    if (openCalls?.length > 0) {
      allCalls.value = [...openCalls]
    }
  })

  const {
    loading: displayNotesLoading,
    onError: onDisplayNotesError,
    result: displayNotesResult
  } = useQuery<ActiveDisplayNotesQuery>(QueryActiveDisplayNotes, {}, { fetchPolicy: 'no-cache' })

  onDisplayNotesError((error) => {
    logError('Failed to load displaynotes', error)
    store.commit('notifications/notify', {
      title: i18n.t('displaynote.notifications.error.title'),
      text: i18n.t('displaynote.notifications.error.title'),
      type: 'error',
      persist: true
    })
  })

  const displayNotes = computed(() => displayNotesResult.value?.activeDisplayNotes ?? [])
  const loading = computed(() => openCallsLoading.value || displayNotesLoading.value)

  const { result } = useSubscription(CallsAddedSubscription, {}, { fetchPolicy: 'no-cache' })
  watch(result, data => {
    allCalls.value = data.callsChanged
  })

  // Check if section filters should be ignored at this time.
  const isIgnoredSectionTime = computed(() => {
    if (!settings.value.ignoreSectionsTimeStart || !settings.value.ignoreSectionsTimeEnd) {
      return false
    }
    const intStart = timeToInt(settings.value.ignoreSectionsTimeStart)
    const intEnd = timeToInt(settings.value.ignoreSectionsTimeEnd)
    const now = timeToInt(format(time.value.now, 'HH:mm'))

    // Overlaps midnight.
    if (intStart > intEnd) {
      return now >= intStart || now <= intEnd
    }

    return now >= intStart && now <= intEnd
  })

  const callsOnly = computed(() => allCalls.value.filter(c => c?.call_type?.flag !== CallTypeFlag.Presence))
  const presencesOnly = computed(() => allCalls.value.filter(c => c?.call_type?.flag === CallTypeFlag.Presence))

  const applySectionsFilter = computed(() => settings.value.sections && settings.value.sections.length > 0 && !isIgnoredSectionTime.value)

  const currentDisplayGroupConfig = computed(() => settings.value.displayGroupConfig)

  const {
    useDisplayGroupFilter,
    activeDisplayGroupSectionCallTypeMap
  } = useDisplayGroupFilterCallTypeMap(currentDisplayGroupConfig, schedules, displayGroups, {
    start: currentSystem.value?.weekend_start ?? 0,
    stop: currentSystem.value?.weekend_stop ?? 0
  })

  const calls = computed(() => {
    if (useDisplayGroupFilter.value) {
      const filtered = callsOnly.value.filter(call => activeDisplayGroupSectionCallTypeMap.value[call.section_id]?.includes(call.call_type_id))

      return sortCalls(filtered)
    }

    if (applySectionsFilter.value) {
      const filtered = callsOnly.value.filter(
        call => settings.value.sections?.includes(call.section_id) || settings.value.ignoreSectionsFilterForCallTypes?.includes(call.call_type_id)
      )

      return sortCalls(filtered)
    }

    return sortCalls(callsOnly.value)
  })

  const presences = computed(() => {
    if (useDisplayGroupFilter.value) {
      const filtered = presencesOnly.value.filter(call => activeDisplayGroupSectionCallTypeMap.value[call.section_id]?.includes(call.call_type_id))

      return sortCalls(filtered)
    }

    if (applySectionsFilter.value) {
      const filtered = presencesOnly.value.filter(
        call => settings.value.sections?.includes(call.section_id) || settings.value.ignoreSectionsFilterForCallTypes?.includes(call.call_type_id)
      )

      return sortCalls(filtered)
    }

    return sortCalls(presencesOnly.value)
  })

  const displayConfig: LayoutDisplayConfig = {
    calls: {
      title: 'device_text_short',
      subtitle: 'device_text_long',
      showCallSourceChar: true,
      bolds: [
        'call_type_text'
      ],
      lines: [
        'position_or_call_source_text',
        'section_name',
      ]
    },
    presences: {
      title: 'device_text_short',
      showCallSourceChar: true,
      bolds: [],
      lines: [
        'section_name',
        'time'
      ]
    }
  }
</script>

<template>
  <ViewScaffold title="common.modules.visual" subtitle="call.view.subtitle" classes="mb-4">
    <div class="display" :class="{'p-10': isFullscreen}">
      <Loader v-if="loading" />
      <Callout v-else-if="openCallsError" type="error" :text="openCallsError.message" />
      <div v-else>
        <Display
          :calls="calls"
          :presences="presences"
          :displayConfig="displayConfig"
          :fullscreen="isFullscreen"
          :videoStream="videoStream"
          :layout="settings.layout"
          :relations="relations"
          :displayGroups="displayGroups"
          :schedules="schedules"
          :displayNotes="displayNotes"
          :fontSize="settings.fontSize"
          @close="callToBeClosed = $event"
          @setLayout="settings.layout = $event"
          @openSettings="settingsDrawerVisible = true"
        />
      </div>
    </div>

    <teleport to="body">
      <transition name="popup">
        <CloseCallConfirmationPopup
          v-if="callToBeClosed !== null"
          :call="callToBeClosed"
          :allCalls="allCalls"
          :displayConfig="displayConfig.calls"
          :relations="relations"
          @success="callToBeClosed = null"
          @cancel="callToBeClosed = null"
        />
      </transition>
    </teleport>

    <DisplaySettingsDrawer
      v-model="settings"
      :isOpen="settingsDrawerVisible"
      :schedules="schedules"
      :displayGroups="displayGroups"
      @close="settingsDrawerVisible = false"
    />
  </ViewScaffold>
</template>

<style lang="stylus" scoped>
  .appear-enter-active,
  .appear-leave-active
    transition .5s ease-out
    transition-property transform, opacity

  .appear-enter-from,
  .appear-leave-to
    transform scale(0)
    opacity 0

  .appear-to,
  .appear-leave-from
    transform scale(1)
    opacity 1
</style>
