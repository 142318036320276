import { DisplayGroup, Schedule } from '@/common/graphql/types'
import { computed, Ref } from 'vue'
import { toFormDropdownOptions } from '@/common/components/form/Forms.api'
import { Composer } from 'vue-i18n'
import { add, formatTime, useTime } from '@/common/services/time'

export interface DisplayGroupConfiguration {
  weekday_schedule_id?: number | null,
  weekday_display_group_1_id?: number | null,
  weekday_display_group_2_id?: number | null,
  weekday_display_group_3_id?: number | null,
  weekday_display_group_4_id?: number | null,
  weekday_display_group_5_id?: number | null,

  weekend_schedule_id?: number | null,
  weekend_display_group_1_id?: number | null,
  weekend_display_group_2_id?: number | null,
  weekend_display_group_3_id?: number | null,
  weekend_display_group_4_id?: number | null,
  weekend_display_group_5_id?: number | null,
}

export interface DisplayGroupOptions {
  add_gets_defaults?: boolean;
  empty_option_name: string;
  add_app_defaults?: boolean;
}

// GenericDisplayGroup is a stand-in type for the AppDisplayGroup and DisplayGroup types.
interface GenericDisplayGroup {
  id?: string;
  name: string;
}

// useDisplayGroups provides the basic API to work with display groups and schedule slots.
export function useDisplayGroups (
  i18n: Composer,
  data: Ref<DisplayGroupConfiguration>,
  schedules: Ref<ReadonlyArray<Partial<Schedule>>>,
  displayGroups: Ref<ReadonlyArray<Partial<GenericDisplayGroup>>>,
  opts: DisplayGroupOptions = { empty_option_name: 'schedule.none' }
) {
  // getRelevantTimes returns all non-empty time fields.
  function getRelevantTimes (s: Partial<Schedule>) {
    return [s.time_1, s.time_2, s.time_3, s.time_4, s.time_5].filter(Boolean)
  }

  const weekdaySchedule = computed(() => {
    return schedules.value.find(i => i.id?.toString() === data.value.weekday_schedule_id?.toString())
  })

  const weekdayScheduleRelevantTimes = computed(() => {
    if (!weekdaySchedule.value) {
      return ['00:00', '00:00']
    }
    const times = getRelevantTimes(weekdaySchedule.value)
    // Add the first time at the and again (as the last time-frame ends when the first time-frame starts).
    return [...times, times[0]]
  })

  const weekendSchedule = computed(() => {
    return schedules.value.find(i => i.id?.toString() === data.value.weekend_schedule_id?.toString())
  })
  const weekendScheduleRelevantTimes = computed(() => {
    if (!weekendSchedule.value) {
      return ['00:00', '00:00']
    }
    const times = getRelevantTimes(weekendSchedule.value)
    // Add the first time at the end again (as the last time-frame ends when the first time-frame starts).
    return [...times, times[0]]
  })

  const scheduleOptions = computed(() => {
    const options = []
    if (opts.add_gets_defaults) {
      options.push({ value: 255, label: i18n.t(opts.empty_option_name) })
      options.push({ value: 20, label: i18n.t('schedule.allday') })
    }
    options.push(
      ...toFormDropdownOptions<Partial<Schedule>>(schedules.value, {
        value: 'id',
        label: i => `${i.name} (${getRelevantTimes(i).join(', ')})`,
        nullLabel: opts.add_app_defaults ? i18n.t(opts.empty_option_name) : undefined,
      })
    )
    return options
  })

  const displayGroupOptions = computed(() => {
    const options = []
    if (opts.add_gets_defaults) {
      options.push(
        { value: 255, label: i18n.t('displaygroup.none') },
        { value: 254, label: i18n.t('displaygroup.all') },
        { value: 253, label: i18n.t('displaygroup.section') }
      )
    }
    options.push(
      ...toFormDropdownOptions<Partial<DisplayGroup>>(displayGroups.value, {
        value: 'id',
        label: 'name',
        nullLabel: opts.add_app_defaults ? i18n.t('displaygroup.none') : undefined,
      })
    )
    return options
  })

  return {
    weekdayScheduleRelevantTimes,
    weekendScheduleRelevantTimes,
    scheduleOptions,
    displayGroupOptions,
  }
}

// useDisplayGroupFilterCallTypeMap returns a map of active section/calltype pairs for the current time based on a schedule/displaygroup input.
export function useDisplayGroupFilterCallTypeMap (displayGroupConfig: Ref<DisplayGroupConfiguration>, schedules: Ref<Schedule[]>, displayGroups: Ref<Partial<DisplayGroup>[]>, weekendConfig: { start: number, stop: number }) {
  const time = useTime()

  const isWeekend = computed(() => {
    const now = new Date(time.value.now)

    // Tuesday to Thursday is never a weekend.
    if (now.getDay() > 1 && now.getDay() < 5) {
      return false
    }

    const offsetHours = String(-(new Date().getTimezoneOffset() / 60)).padStart(2, '0')

    // Use a monday as the base date. We now add the minute offset from the GETS settings
    // to get two comparable start and end dates.
    const baseDate = new Date(`1970-01-05T00:00:00${Number(offsetHours) > 0 ? '+' : '-'}${offsetHours}:00`)

    let addWeekdays = now.getDay() - 1
    if (addWeekdays < 0) {
      addWeekdays = 6 // Sunday.
    } else if (addWeekdays === 0) {
      addWeekdays = 7 // On mondays, we need to add 7 days to not fall back to the monday of the previous week.
    }

    const weekendStart = add(baseDate, { days: 4, minutes: weekendConfig.start })
    const weekendStop = add(baseDate, { days: 6, minutes: weekendConfig.stop })
    const rebasedNow = add(baseDate, { days: addWeekdays, minutes: now.getMinutes(), hours: now.getHours() })

    return rebasedNow >= weekendStart && rebasedNow <= weekendStop
  })

  const activeScheduleID = computed(() => {
    const schedule = isWeekend.value ? displayGroupConfig.value.weekend_schedule_id : displayGroupConfig.value.weekday_schedule_id

    return schedule === undefined ? -1 : schedule
  })

  const configuredDisplayGroups = computed(() => {
    if (isWeekend.value) {
      return cleanupArrayTail([
        displayGroupConfig.value.weekend_display_group_1_id,
        displayGroupConfig.value.weekend_display_group_2_id,
        displayGroupConfig.value.weekend_display_group_3_id,
        displayGroupConfig.value.weekend_display_group_4_id,
        displayGroupConfig.value.weekend_display_group_5_id,
      ])
    }

    return cleanupArrayTail([
      displayGroupConfig.value.weekday_display_group_1_id,
      displayGroupConfig.value.weekday_display_group_2_id,
      displayGroupConfig.value.weekday_display_group_3_id,
      displayGroupConfig.value.weekday_display_group_4_id,
      displayGroupConfig.value.weekday_display_group_5_id,
    ])
  })

  const schedule = computed(() => schedules.value.find(s => Number(s.id) === Number(activeScheduleID.value)))

  const activeScheduleTimeFrame = computed(() => {
    if (!schedule.value) {
      return -1
    }

    const currentTime = parseInt(formatTime(time.value.now, { showSeconds: true }).replace(':', ''))

    const times = cleanupArrayTail([
      schedule.value.time_1,
      schedule.value.time_2,
      schedule.value.time_3,
      schedule.value.time_4,
      schedule.value.time_5,
    ].map(t => parseInt(t.replace(':', '')))) as number[]

    if (times.length === 0) {
      return -1
    }

    // Time is after the last time.
    if (currentTime <= 2359 && currentTime >= times[times.length - 1]) {
      return times.length - 1
    }

    // Time is before the first time.
    if (currentTime >= 0 && currentTime < times[0]) {
      return times.length - 1
    }

    return times.findLastIndex(t => currentTime >= t)
  })

  const activeDisplayGroup = computed(() => {
    if (!displayGroups.value || activeScheduleTimeFrame.value === -1) {
      return null
    }

    if (activeScheduleTimeFrame.value === -1) {
      return { connections: [] }
    }

    const id = configuredDisplayGroups.value[activeScheduleTimeFrame.value]

    return displayGroups.value.find(dg => Number(dg.id) === Number(id))
  })

  const activeDisplayGroupSectionCallTypeMap = computed(() => {
    if (!activeDisplayGroup.value) {
      return {}
    }

    const map: Record<number, number[]> = {};
    (activeDisplayGroup.value.connections ?? []).forEach(c => {
      if (!map[c.section_id]) {
        map[c.section_id] = []
      }
      map[c.section_id].push(c.call_type_id)
    })
    return map
  })

  // Remove empty values from the end of the array.
  const cleanupArrayTail = (arr: (number|null|undefined)[]) =>  {
    while (arr.length > 0 && [null, undefined, -1, NaN].includes(arr[arr.length - 1])) {
      arr.pop()
    }
    return arr
  }

  return {
    useDisplayGroupFilter: computed(() => displayGroupConfig.value.weekday_schedule_id !== undefined && displayGroupConfig.value.weekend_schedule_id !== undefined),
    activeDisplayGroupSectionCallTypeMap
  }
}
