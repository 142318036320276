<script setup lang="ts">
  import TimeSince from '@/common/components/TimeSince.vue'
  import { Call, CallTypeFlag } from '@/common/graphql/types'
  import {
    CallDisplayConfig,
    CallDisplayRelations,
    getDisplayValue,
    useCallDisplay,
    useShowCloseAction
  } from '@/modules/display/composables/Calls.api'
  import { useStore } from '@/store'
  import { computed, PropType, ref } from 'vue'
  import { mdiHumanHandsdown, mdiRun } from '@mdi/js'
  import { useRouter } from 'vue-router'
  import MdiIcon from '@/common/components/svg/MdiIcon.vue'

  defineEmits(['close'])
  const props = defineProps({
    call: {
      type: Object as PropType<Call>,
      default: () => ({} as Call)
    },
    displayConfig: {
      type: Object as PropType<CallDisplayConfig>,
      default: () => ({} as CallDisplayConfig)
    },
    showTime: {
      type: Boolean,
      default: () => true
    },
    relations: {
      type: Object as PropType<CallDisplayRelations>,
      default: () => ({})
    },
    showCloseCallAction: {
      type: Boolean,
      default: true,
    },
  })

  const store = useStore()
  const router = useRouter()
  const {
    isElevated,
    lines,
    bolds
  } = useCallDisplay(ref(props.call), ref(props.displayConfig))

  const showCloseAction = computed(() => {
    if (!props.showCloseCallAction) {
      return false
    }
    return useShowCloseAction(store, ref(props.call)).value
  })

  const userIsLoggedIn = computed(() => store.state.user.user.id > 0)

  function onClick () {
    if (userIsLoggedIn.value) {
      router.push({ name: 'displayCallForm', params: { id: props.call.id } })
      return
    }
  }

  const isPresence = computed(() => {
    return props.call.call_type?.flag === CallTypeFlag.Presence
  })
</script>

<template>
  <div
    class="call border border-gray-300 p-4 pb-6 relative h-full rounded shadow bg-white leading-tight"
    :class="{'is-elevated': isElevated, 'cursor-pointer': userIsLoggedIn, 'is-presence': isPresence, 'is-call': !isPresence}"
    :data-call="call.id"
    :style="{'border-color': isElevated && call.call_type ? call.call_type.color : ''}"
    @click="onClick"
  >
    <div
      class="colored-line absolute bg-blue-400 bottom-0 inset-x-0 z-10 h-2"
      :style="{'background-color': call.call_type ? call.call_type.color : ''}"
    />
    <div
      v-if="isElevated"
      class="absolute bg-blue-400 inset-0 z-0 h-full opacity-80"
      :style="{'background-color': call.call_type ? call.call_type.color : ''}"
    />
    <div class="flex flex-col justify-between h-full z-50 relative" :class="{'text-white font-bold': isElevated}">
      <div class="flex justify-between items-start">
        <div class="flex-1 min-w-0">
          <div class="mb-4 title whitespace-nowrap overflow-hidden truncate">
            {{ call[displayConfig.title] ? call[displayConfig.title] : $t('call.states.unknown') }}
          </div>
          <div
            v-if="displayConfig.subtitle && call[displayConfig.subtitle]"
            class="mb-4 subtitle whitespace-nowrap overflow-hidden truncate -mt-3"
          >
            {{ call[displayConfig.subtitle] }}
          </div>
        </div>
        <div
          v-if="(displayConfig.showCallSourceChar && relations.callSources[call.call_source_id]) || call.handled_by_app_username"
          class="flex-0 title ml-2 flex flex-col items-end"
        >
          <div v-if="displayConfig.showCallSourceChar && relations.callSources[call.call_source_id]">
            {{ relations.callSources[call.call_source_id].char }}
          </div>
          <div v-if="call.handled_by_app_username" class="flex text-sm items-center" :class="isElevated ? 'text-white' : 'text-black'">
            <MdiIcon class="fill-current w-[15px] mr-1" :icon="isPresence ? mdiHumanHandsdown : mdiRun" /> {{ call.handled_by_app_username }}
          </div>
        </div>
      </div>
      <div
        class="flex justify-between"
        :class="{
          'flex-col items-start': showTime,
          'items-end': !showTime,
        }"
      >
        <div class="w-full text-base leading-snug lines">
          <div v-for="line in bolds" :key="line" class="whitespace-nowrap overflow-hidden truncate font-semibold">
            {{ getDisplayValue(call, line) }}
          </div>
          <div v-for="line in lines" :key="line" class="whitespace-nowrap overflow-hidden truncate">
            {{ getDisplayValue(call, line) }}
          </div>
        </div>
        <div
          v-if="showTime || showCloseAction"
          class="mt-2 translate-y-[2px] flex items-baseline justify-between"
          :class="{'w-full': showTime}"
        >
          <TimeSince
            v-if="showTime"
            class="text-sm whitespace-nowrap leading-snug"
            :time="call.opened_at"
          />
          <div
            v-if="showCloseAction"
            v-tooltip="{ content: $t('call.actions.close_call'), delay: 1500 }"
            class="close-action rounded leading-none py-1 px-2 ml-2 translate-y-[1px] -mr-2 text-gray-600 cursor-pointer font-bold text-2xl hover:bg-gray-100"
            @click.stop="$emit('close', call)"
          >
            &times;
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="stylus" scoped>
.call
  min-width 240px
  container call / inline-size

  &.is-elevated .close-action
    color #fff

    &:hover
      background-color rgba(255, 255, 255, .9)
      color #000

.title
  font-size 1.5rem

.subtitle
  font-size 1.2rem

.lines
  min-width 0
</style>
