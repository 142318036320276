<script setup lang="ts">
  import { computed, PropType, ref, watch } from 'vue'
  import { DisplayParams, displayParamsToUrl } from '@/modules/display/composables/Calls.api'
  import FormInput from '@/common/components/form/FormInput.vue'
  import { useQuery } from '@vue/apollo-composable'
  import { DisplayGroup, DisplaySettingsOptionsQuery, Locale, Schedule } from '@/common/graphql/types'
  import QueryDisplaySettingsOptions from './../graphql/QueryDisplaySettingsOptions.gql'
  import FormDropdown from '@/common/components/form/FormDropdown.vue'
  import { useI18n } from 'vue-i18n'
  import FadeoutReveal from '@/common/components/FadeoutReveal.vue'
  import Drawer from '@/common/components/Drawer.vue'
  import DisplayGroupScheduleSelector from '@/modules/admin/displaygroup/components/DisplayGroupScheduleSelector.vue'
  import FormCheckbox from '@/common/components/form/FormCheckbox.vue'

  const config = defineModel<DisplayParams>({
    default: {
      locale: Locale.De,
      layout: 'grid',
      fontSize: 'normal',
      fullscreen: false,
      videoStream: false,
      displayGroupConfig: {},
      sections: [],
      ignoreSectionsTimeStart: undefined,
      ignoreSectionsTimeEnd: undefined,
      ignoreSectionsFilterForCallTypes: [],
    }
  })

  defineEmits(['close'])
  defineProps({
    isOpen: {
      type: Boolean,
      default: false,
    },
    displayGroups: {
      type: Array as PropType<ReadonlyArray<Partial<DisplayGroup>>>,
      default: () => []
    },
    schedules: {
      type: Array as PropType<ReadonlyArray<Partial<Schedule>>>,
      default: () => []
    },
  })

  const i18n = useI18n()

  const sectionsRevealed = ref(false)

  const showSchedules = ref(config.value?.displayGroupConfig?.weekday_schedule_id !== undefined && config.value?.displayGroupConfig?.weekend_schedule_id !== undefined)

  const queryString = computed(() => {
    if (!config.value) {
      return ''
    }
    return decodeURIComponent(displayParamsToUrl(window.location, config.value).toString())
  })

  watch(queryString, (value) => window.history.replaceState({}, '', value))

  watch(() => showSchedules.value, (value) => {
    if (!config.value) {
      return
    }
    if (!value) {
      config.value.displayGroupConfig = {}
    } else {
      config.value.ignoreSectionsTimeEnd = undefined
      config.value.ignoreSectionsTimeStart = undefined
      config.value.ignoreSectionsFilterForCallTypes = []
      config.value.sections = []
      config.value.displayGroupConfig = {
        weekday_schedule_id: null,
        weekend_schedule_id: null,
      }
    }
  })

  function focusAndCopy (e: Event) {
    e.preventDefault();

    (e.target as HTMLInputElement).select()

    setClipboard(queryString.value)
  }

  function setClipboard (text: string) {
    const type = 'text/plain'
    const blob = new Blob([text], { type })
    const data = [new ClipboardItem({ [type]: blob })]

    navigator.clipboard.write(data)
  }

  const { result } = useQuery<DisplaySettingsOptionsQuery>(QueryDisplaySettingsOptions)

  const sections = computed(() => [...result.value?.sections ?? []].sort((a, b) => Number(a.id) - Number(b.id)))
  const callTypes = computed(() => [...result.value?.callTypes ?? []].sort((a, b) => Number(a.id) - Number(b.id)))

  const layoutOptions = [
    { value: 'grid', label: i18n.t('call.display.view_grid') },
    { value: 'list', label: i18n.t('call.display.view_list') },
  ]

  const languageOptions = [
    { value: Locale.De, label: i18n.t('common.locales.de') },
    { value: Locale.En, label: i18n.t('common.locales.en') },
  ]

  const fontSizeOptions = [
    { value: 'normal', label: i18n.t('display.config.font_size_normal') },
    { value: 'larger', label: i18n.t('display.config.font_size_larger') },
    { value: 'largest', label: i18n.t('display.config.font_size_largest') },
  ]
</script>

<template>
  <Drawer
    title="display.settings"
    :isOpen="isOpen"
    @close="$emit('close')"
  >
    <div class="m-4 space-y-4">
      <div class="bg-white border border-gray-300 p-4 rounded shadow">
        <div class="text-sm mb-4">
          {{ $t('display.config.query_string') }}<br>
          <div class="text-gray-600">
            {{ $t('display.config.query_string_comment') }}
          </div>
        </div>
        <FormTextarea
          v-model="queryString"
          :readonly="true"
          :autoResize="false"
          name="queryString"
          @click="focusAndCopy"
        />
        <div class="flex justify-end mt-2">
          <a :href="queryString" target="_blank" class="text-xs">
            {{ $t('display.actions.show_preview') }} &rarr;
          </a>
        </div>
      </div>

      <div class="bg-white border border-gray-300 p-4 rounded shadow">
        <div class="text-sm border-b pb-2 mb-4">
          {{ $t('display.config.display_settings') }}<br>
        </div>
        <div class="grid grid-cols-3 gap-4 mb-4">
          <div>
            <FormDropdown
              v-model="config.locale"
              name="locale"
              label="display.config.locale"
              :options="languageOptions"
            />
          </div>
          <div>
            <FormDropdown
              v-model="config.layout"
              name="layout"
              label="display.config.layout"
              :options="layoutOptions"
            />
          </div>
          <div>
            <FormDropdown
              v-model="config.fontSize"
              name="fontSize"
              label="display.config.font_size"
              :options="fontSizeOptions"
            />
          </div>
        </div>
        <div class="grid grid-cols-2 gap-2">
          <div>
            <FormCheckbox
              v-model="config.fullscreen"
              name="fullscreen"
              label="display.config.fullscreen"
            />
          </div>
          <div>
            <FormCheckbox
              v-model="config.videoStream"
              name="videoStream"
              label="display.config.video_stream"
            />
          </div>
        </div>
      </div>

      <div class="bg-white border border-gray-300 p-4 rounded shadow">
        <div class="text-sm flex items-center justify-between" :class="{'border-b pb-2 mb-4': showSchedules}">
          <div>
            {{ $t('schedule.singular') }}
          </div>
          <div>
            <FormCheckbox
              v-model="showSchedules"
              name="showSchedule"
              label="display.config.show_schedule"
              class="ml-auto"
              labelClass="!pl-2.5"
            />
          </div>
        </div>

        <DisplayGroupScheduleSelector
          v-if="showSchedules"
          v-model="config.displayGroupConfig"
          :schedules="schedules"
          :displayGroups="displayGroups"
          :optionsConfig="{ empty_option_name: 'displaygroup.none' }"
          :condensed="true"
        />
      </div>

      <div v-if="!showSchedules" class="bg-white border border-gray-300 p-4 rounded shadow">
        <div class="text-sm mb-4">
          {{ $t('display.config.sections') }}<br>
          <div class="text-gray-600">
            {{ $t('display.config.sections_comment') }}
          </div>
        </div>
        <FadeoutReveal
          class="space-y-0.5"
          :enabled="sections.length > 8"
          :toggle="true"
          @reveal="sectionsRevealed = $event"
        >
          <transition-group name="fade">
            <FormCheckbox
              v-for="section in sections"
              :id="'section-checkbox-' + section.id"
              :key="section.id"
              v-model="config.sections"
              :inputValue="Number(section.id)"
              :label="section.name"
            />
          </transition-group>
        </FadeoutReveal>
      </div>

      <transition name="fade">
        <div
          v-if="Number(config.sections?.length) > 0 && !showSchedules"
          class="bg-white border border-gray-300 p-4 rounded shadow"
        >
          <div class="text-sm mb-4">
            {{ $t('display.config.ignore_sections_filter') }}<br>
            <div class="text-gray-600">
              {{ $t('display.config.ignore_sections_time') }}:
            </div>
          </div>
          <div class="grid grid-cols-2 gap-4">
            <div>
              <FormInput
                v-model="config.ignoreSectionsTimeStart"
                name="start"
                maxlength="5"
                type="time"
                label="display.config.ignore_sections_time_start"
              />
            </div>
            <div>
              <FormInput
                v-model="config.ignoreSectionsTimeEnd"
                name="start"
                maxlength="5"
                type="time"
                label="display.config.ignore_sections_time_end"
              />
            </div>
          </div>
          <div class="mt-8">
            <div class="text-sm text-gray-600 mb-4">
              {{ $t('display.config.ignore_sections_filter_for_call_types') }}:
            </div>
            <div class="grid grid-cols-2 gap-1">
              <FormCheckbox
                v-for="callType in callTypes"
                :id="'callType-checkbox-' + callType.id"
                :key="callType.id"
                v-model="config.ignoreSectionsFilterForCallTypes"
                :inputValue="Number(callType.id)"
                :label="callType.name"
              />
            </div>
          </div>
        </div>
      </transition>
    </div>
  </Drawer>
</template>