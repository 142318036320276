<template>
  <ConfirmPopup
    actionType="primary"
    :action="$t('call.popups.close.title')"
    :title="$t('call.popups.close.title')"
    :loaderText="$t('call.popups.close.loader_text')"
    :loading="loading"
    @confirm="confirm"
    @cancel="$emit('cancel')"
  >
    <p class="mb-4">
      {{ $t('call.popups.close.message') }}
    </p>
    <Callout v-if="hasError" class="my-4" type="error" :text="$t('call.popups.close.error')" />
    <Call
      :call="call"
      :relations="relations"
      :displayConfig="displayConfig"
      :showCloseCallAction="false"
    />
  </ConfirmPopup>
</template>

<script lang="ts">
  import { computed, defineComponent, PropType, ref } from 'vue'
  import ConfirmPopup from '@/common/components/popup/ConfirmPopup.vue'
  import { Call as CallType, DeviceActionMutation, DeviceActionMutationVariables, DeviceActionType } from '@/common/graphql/types'
  import Call from '@/modules/display/components/Call.vue'
  import { CallDisplayConfig, CallDisplayRelations, defaultDisplayConfig } from '@/modules/display/composables/Calls.api'
  import { useMutation } from '@vue/apollo-composable'
  import MutationDeviceAction from '@/modules/device/graphql/systemtreenode/MutationDeviceAction.gql'
  import { logError, sleep } from '@/common/utils'
  import Callout from '@/common/components/callout/Callout.vue'
  import { useStore } from '@/store'
  import { useI18n } from 'vue-i18n'

  export default defineComponent({
    name: 'CloseCallConfirmationPopup',
    components: {
      ConfirmPopup,
      Callout,
      Call,
    },
    props: {
      call: {
        type: Object as PropType<CallType>,
        required: true,
      },
      allCalls: {
        type: Array as PropType<CallType[]>,
        required: true,
      },
      displayConfig: {
        type: Object as PropType<CallDisplayConfig>,
        default: () => defaultDisplayConfig
      },
      relations: {
        type: Object as PropType<CallDisplayRelations>,
        default: () => ({})
      }
    },
    emits: ['success', 'cancel'],
    setup (props, { emit }) {
      const store = useStore()
      const i18n = useI18n()
      const hasError = ref(false)
      const loading = ref(false)

      const callIsOpen = computed(() => props.allCalls.some(call => call.id === props.call.id))

      const { mutate, onError } = useMutation<DeviceActionMutation, DeviceActionMutationVariables>(MutationDeviceAction, {
        variables: {
          input: {
            action: DeviceActionType.EndCalls,
            deviceID: props.call.number,
            sectionID: props.call.section_id,
          },
        }
      })

      onError((err) => {
        logError('device action failed', err)
        hasError.value = true
        loading.value = false
      })

      async function confirm () {
        loading.value = true
        hasError.value = false

        await mutate()

        // Check if the call is still open.
        for (let retry = 0; retry < 15; retry++) {
          if (!callIsOpen.value) {
            store.commit('notifications/notify', {
              type: 'success',
              title: i18n.t('call.notifications.close_call_success.title'),
              text: i18n.t('call.notifications.close_call_success.text'),
            })
            return emit('success', props.call)
          }
          await sleep(500)
        }

        hasError.value = true
        loading.value = false
      }

      return {
        loading,
        hasError,
        confirm,
      }
    }
  })
</script>

