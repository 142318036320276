<template>
  <div
    class="notification-list fixed inset-0 flex items-end justify-center transition transition-opacity duration-200 p-4 pointer-events-none sm:p-6 sm:items-end sm:justify-end z-50"
    :class="{'opacity-100': current, 'opacity-0 delay-300': !current}"
  >
    <transition
      enterActiveClass="notification-easing delay-200 duration-300 transition"
      enterFromClass="translate-x-full opacity-0"
      enterToClass="translate-x-0 opacity-100"
      leaveActiveClass="notification-easing duration-300 transition"
      leaveFromClass="translate-x-0 opacity-100"
      leaveToClass="translate-x-full opacity-0"
    >
      <Notification
        v-if="current"
        :actions="current.actions"
        :type="current.type"
        @close="dismiss(current)"
      >
        <template #title>
          {{ current.title }}
        </template>
        {{ current.text }}
      </Notification>
    </transition>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, watch } from 'vue'
  import { useStore } from '@/store'
  import { Notification as NotificationInterface } from './Notification.api'
  import Notification from './Notification.vue'

  export default defineComponent({
    name: 'NotificationList',
    components: {
      Notification,
    },
    setup () {
      const store = useStore()
      const notifications = computed<NotificationInterface[]>(() => store.getters['notifications/active'])
      const current = computed(() => notifications.value.length > 0 ? notifications.value[0] : false)

      // Set a close timeout if the current notification is not a persistent one.
      let closeTimeout: ReturnType<typeof setTimeout>
      watch(() => current.value, newV => {
        if (newV === false) {
          return
        }
        clearTimeout(closeTimeout)
        if (!newV.persist) {
          closeTimeout = setTimeout(() => {
            if (current.value !== false) {
              store.commit('notifications/dismiss', current.value)
            }
          }, newV.duration)
        }
      })

      function dismiss (notification: NotificationInterface | false) {
        if (notification === false) {
          return
        }
        store.commit('notifications/dismiss', notification)
      }

      return {
        current,
        dismiss
      }
    }
  })
</script>

<style lang="stylus" scoped>
  .notification-list
    background-image radial-gradient(circle at 120% 170%, rgba(0, 0, 0, .8), rgba(0, 0, 0, 0) 50%)

  .notification-easing
    transition-timing-function cubic-bezier(.39, .97, .36, 1.07)
</style>
