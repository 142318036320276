import {
  add as addFn,
  differenceInDays as differenceInDaysFn,
  differenceInSeconds as differenceInSecondsFn,
  endOfDay as endOfDayFn,
  endOfMonth as endOfMonthFn,
  endOfWeek as endOfWeekFn,
  endOfYear as endOfYearFn,
  FirstWeekContainsDate,
  format as formatFn,
  formatDistance as formatDistanceFn,
  formatDistanceStrict as formatDistanceStrictFn,
  formatISO as formatISOFn,
  isBefore as isBeforeFn,
  parse as parseFn,
  parseISO as parseISOFn,
  startOfDay as startOfDayFn,
  startOfMonth as startOfMonthFn,
  startOfWeek as startOfWeekFn,
  startOfYear as startOfYearFn,
  sub as subFn,
} from 'date-fns'
import { Ref, ref } from 'vue'
import { de } from 'date-fns/locale'
import { useI18n } from 'vue-i18n'
import { logError } from '@/common/utils'
import { Locale } from 'date-fns/locale/types'
import { Duration } from 'date-fns/types'

export interface Time {
  now: number
}

const time = ref({
  now: Date.now()
})

type dateInput = Date | number

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const tickInterval = setInterval(() => {
  time.value.now += 1000
}, 1000)

export function useNow () {
  return time.value.now
}

export function useTime (): Ref<Time> {
  return time
}

export function setNow (value: number) {
  time.value.now = value
}

export function parseISO (iso: string) {
  return parseISOFn(iso)
}

export function parseTime (time: string, reference: Date = new Date()) {
  return parseFn(time, 'HH:mm', reference)
}

export function parseDate (time: string, reference: Date = new Date()) {
  return parseFn(time, 'yyyy-MM-dd', reference)
}

export function startOfDay (date: dateInput) {
  return startOfDayFn(date)
}

export function endOfDay (date: dateInput) {
  return endOfDayFn(date)
}

export function startOfWeek (date: dateInput) {
  return startOfWeekFn(date, { weekStartsOn: 1 })
}

export function endOfWeek (date: dateInput) {
  return endOfWeekFn(date, { weekStartsOn: 1 })
}

export function startOfMonth (date: dateInput) {
  return startOfMonthFn(date)
}

export function endOfMonth (date: dateInput) {
  return endOfMonthFn(date)
}

export function startOfYear (date: dateInput) {
  return startOfYearFn(date)
}

export function endOfYear (date: dateInput) {
  return endOfYearFn(date)
}

export function format (date: dateInput, format: string, options?: {
  locale?: Locale;
  weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  firstWeekContainsDate?: FirstWeekContainsDate;
  useAdditionalWeekYearTokens?: boolean;
  useAdditionalDayOfYearTokens?: boolean;
}) {
  // Fixes local timezone offset by adding the required Minutes to the date.
  return formatFn(date, format, {
    locale: de,
    weekStartsOn: 1,
    ...options
  })
}

export function isBefore (date: Date | number, dateToCompare: Date | number) {
  return isBeforeFn(date, dateToCompare)
}

export function formatDateTime (date: Date, options?: {
  showSeconds?: boolean
}) {
  return format(date, 'dd.MM.yyyy HH:mm' + (options?.showSeconds ? ':ss' : ''))
}

export function formatDate (date: Date) {
  return format(date, 'dd.MM.yyyy')
}

export function formatTime (date: dateInput, options?: {
  showSeconds?: boolean
}) {
  return format(date, 'HH:mm' + (options?.showSeconds ? ':ss' : ''))
}

export function formatISO (date: dateInput, options?: { format?: 'extended' | 'basic'; representation?: 'complete' | 'date' | 'time' }) {
  return formatISOFn(date, options)
}

export function sub (date: dateInput, duration: Duration) {
  return subFn(date, duration)
}

export function add (date: dateInput, duration: Duration) {
  return addFn(date, duration)
}

export function formatDistanceToNow (date: dateInput, options?: {
  includeSeconds?: boolean;
  addSuffix?: boolean;
  locale?: Locale;
}) {
  return formatDistanceFn(date, time.value.now, { locale: de, ...options })
}

export function formatDistanceStrict (date: dateInput, base: dateInput, options?: {
  addSuffix?: boolean;
  roundingMethod?: 'floor' | 'ceil' | 'round';
  unit?: 'second' | 'minute' | 'hour' | 'day' | 'month' | 'year';
  locale?: Locale;
}) {
  return formatDistanceStrictFn(date, base, { locale: de, ...options })
}

export function formatDistanceStrictToNow (date: dateInput, options?: {
  addSuffix?: boolean;
  roundingMethod?: 'floor' | 'ceil' | 'round';
  unit?: 'second' | 'minute' | 'hour' | 'day' | 'month' | 'year';
  locale?: Locale;
}) {
  return formatDistanceStrictFn(date, time.value.now, { locale: de, ...options })
}

export function differenceInSeconds (date: dateInput, base: dateInput) {
  return differenceInSecondsFn(date, base)
}

export function differenceInDays (date: dateInput, base: dateInput) {
  return differenceInDaysFn(date, base)
}

export interface DateRange {
  startDate: Date;
  endDate: Date;
}

interface ShortLocale {
  seconds: string;
  minutes: string;
  hours: string;
  days: string;
  just_now: string;
}

export function buildShortLocale (): ShortLocale {
  const i18n = useI18n()
  return {
    seconds: i18n.t('common.duration.seconds_short').toString(),
    minutes: i18n.t('common.duration.minutes_short').toString(),
    hours: i18n.t('common.duration.hours_short').toString(),
    days: i18n.t('common.duration.days_short').toString(),
    just_now: i18n.t('common.duration.just_now').toString(),
  }
}

export function formatSecondsAsString (value: number, options: { locale: ShortLocale; }): string {
  const days = Math.floor(value / 60 / 60 / 24)
  const hours = Math.floor(value / 60 / 60) - (days * 24)
  const minutes = Math.floor(value / 60) - (hours * 60)
  const seconds = Math.floor(value % 60)

  function removeZeroValues (input: string) {
    return input.replace(/ 0\s?[a-z]+\s*/, '')
  }

  if (minutes < 1 && hours < 1) {
    return removeZeroValues(`${seconds}${options.locale.seconds}`)
  } else if (hours < 1) {
    return removeZeroValues(`${minutes}${options.locale.minutes} ${seconds}${options.locale.seconds}`)
  } else if (days < 1) {
    return removeZeroValues(`${hours}${options.locale.hours} ${minutes}${options.locale.minutes}`)
  } else {
    return removeZeroValues(`${days}${options.locale.days} ${hours}${options.locale.hours}`)
  }
}

// timeToInt turns a time into its int representation: "08:00" -> 800, "12:52" -> 1252
export function timeToInt (time: string): number {
  try {
    const out = Number(time.replace(':', ''))
    if (isNaN(out)) {
      throw new Error(`${time} could not be converted to number`)
    }
    return out
  } catch (e) {
    logError(`time to int conversion for ${time} failed`, e)
    return 0
  }
}
