import { Call, CallSource, Locale } from '@/common/graphql/types'
import { computed, ComputedRef, Ref } from 'vue'
import { Store } from 'vuex'
import { State } from '@/store'
import { formatDistanceStrict, parseISO, useTime } from '@/common/services/time'
import { RouteLocation } from 'vue-router'
import { DisplayGroupConfiguration } from '@/modules/admin/displaygroup/DisplayGroup.api'

// sortCalls by their urgency, then their type's sort_order, then by their duration (longest first).
export function sortCalls (calls: Call[]): Call[] {
  const urgency = ['NORMAL', 'URGENT', 'EMERGENCY']
  calls.sort((a, b) => {
    const urgencyA = a?.call_type?.urgency?.toUpperCase() ? urgency.indexOf(a?.call_type?.urgency?.toUpperCase()) : 0
    const urgencyB = b?.call_type?.urgency?.toUpperCase() ? urgency.indexOf(b?.call_type?.urgency?.toUpperCase()) : 0
    const sortA = a?.call_type?.sort_order || 999
    const sortB = b?.call_type?.sort_order || 999

    return urgencyB - urgencyA || sortA - sortB || a.duration - b.duration
  })
  return calls
}

// useCallDisplay provides all logic to display a Call according to a CallDisplayConfig.
export const useCallDisplay = (call: Ref<Call>, config: Ref<CallDisplayConfig>) => {
  const isElevated = computed(() => {
    const urgency = call.value?.call_type?.urgency?.toUpperCase()
    return !urgency ? false : ['EMERGENCY', 'URGENT'].indexOf(urgency) > -1
  })

  const fn = (line: CallDisplayConfigLine) => {
    if (['time', 'position_or_call_source_text'].includes(line)) {
      return true
    }
    return call.value.hasOwnProperty(line) && call.value[line as keyof Call]
  }

  const lines = computed(() => config.value?.lines?.filter(fn))
  const bolds = computed(() => config.value?.bolds?.filter(fn))

  return {
    isElevated,
    lines,
    bolds,
  }
}

// The close call action is visible for all users with the calllog::close_calls permission.
// It is also visible for all logged-in users if:
// - the section has the allow_manual_call_closure flag set to true
// - the device itself has not set the deny_manual_call_closure to true.
export function useShowCloseAction (store: Store<State>, call: Ref<Call>): ComputedRef<boolean> {
  if (store.getters['user/can']('calllog::close_calls')) {
    return computed(() => true)
  }

  if (call.value.system_tree_node?.deny_manual_call_closure) {
    return computed(() => false)
  }

  return computed(() => call.value.section?.allow_manual_call_closure === true && store.state.user.user.id > 0)
}

export type CallDisplayDynamicConfigLineOptions = 'time' | 'position_or_call_source_text'
export type CallDisplayConfigLine = keyof Call | CallDisplayDynamicConfigLineOptions

// CallDisplayRelations contains lookup tables for related call data.
export interface CallDisplayRelations {
  callSources: Record<number, Pick<CallSource, 'id' | 'char'>>
}

// CallDisplayConfig contains the display layout for a single call.
export interface CallDisplayConfig {
  title: keyof Call;
  subtitle?: keyof Call;
  bolds: CallDisplayConfigLine[];
  lines: CallDisplayConfigLine[];
  showCallSourceChar: boolean
}

export interface LayoutDisplayConfig {
  calls: CallDisplayConfig;
  presences: CallDisplayConfig;
}

// getDisplayValue returns the value for a given key in a call.
export function getDisplayValue (call: Call, key: CallDisplayConfigLine): string {
  if (key == 'time') {
    const time = useTime()

    return formatDistanceStrict(parseISO(call.opened_at), time.value.now + 1000, { // +1000 to prevent rounding problems
      addSuffix: true,
      roundingMethod: 'round'
    })
  }

  if (key === 'call_source_text') {
    return filterCallSourceText(call.call_source_text ?? '')
  }

  if (key == 'position_or_call_source_text') {
    return call.position ? call.position : filterCallSourceText(call.call_source_text)
  }

  return call[key] ?? ''
}

// filterCallSourceText makes sure call sources with a * prefix are
// handled as empty strings. The * prefix determines a hidden call
// source in the GEC system, so we hide it as well.
function filterCallSourceText (text: string) {
  return text.substring(0, 1) === '*' ? '' : text
}

export const defaultDisplayConfig: LayoutDisplayConfig = {
  calls: {
    title: 'device_text_short',
    subtitle: 'device_text_long',
    showCallSourceChar: true,
    bolds: [
      'call_type_text'
    ],
    lines: [
      'position_or_call_source_text',
      'section_name',
    ]
  },
  presences: {
    title: 'device_text_short',
    showCallSourceChar: true,
    bolds: [],
    lines: [
      'position_or_call_source_text',
      'section_name',
    ]
  }
}

// DisplayParams configures a call display.
export interface DisplayParams {
  sections?: number[];
  ignoreSectionsFilterForCallTypes?: number[];
  fullscreen?: boolean;
  videoStream?: boolean;
  layout?: 'grid' | 'list';
  ignoreSectionsTimeStart?: string;
  ignoreSectionsTimeEnd?: string;
  locale?: Locale;
  system?: string;
  fontSize?: 'normal' | 'larger' | 'largest';
  displayGroupConfig: Partial<DisplayGroupConfiguration>;
}

// routeToDisplayParams returns the display params from a route.
export function routeToDisplayParams (route: RouteLocation) {
  const params: DisplayParams = {
    sections: [],
    ignoreSectionsFilterForCallTypes: [],
    locale: Locale.De,
    layout: 'grid',
    fontSize: 'normal',
    displayGroupConfig: {}
  }
  if (typeof route.query?.sections === 'string') {
    params['sections'] = route.query.sections.split(',').map(s => Number(s.trim()))
  }
  if (typeof route.query?.ignore_sections_time === 'string') {
    const timeParts = route.query.ignore_sections_time.split('-').map(s => s.trim())
    params['ignoreSectionsTimeStart'] = timeParts[0]
    params['ignoreSectionsTimeEnd'] = timeParts[1]
  }
  if (typeof route.query?.fullscreen === 'string') {
    params['fullscreen'] = route.query.fullscreen === '1'
  }
  if (typeof route.query?.layout === 'string') {
    params['layout'] = route.query.layout === 'list' ? 'list' : 'grid'
  }
  if (typeof route.query?.videostream === 'string') {
    params['videoStream'] = route.query.videostream === '1'
  }
  if (typeof route.query?.ignore_sections_filter_for_call_types === 'string') {
    params['ignoreSectionsFilterForCallTypes'] = route.query.ignore_sections_filter_for_call_types.split(',').map(s => Number(s.trim()))
  }
  if (typeof route.query?.system === 'string') {
    params['system'] = route.query.system
  }

  if (typeof route.query?.locale === 'string') {
    switch (route.query.locale) {
    case 'en':
      params['locale'] = Locale.En
      break
    case 'de':
      params['locale'] = Locale.De
      break
    }
  }

  if (typeof route.query?.fontsize === 'string') {
    switch (route.query.fontsize) {
    case 'larger':
      params['fontSize'] = 'larger'
      break
    case 'largest':
      params['fontSize'] = 'largest'
      break
    }
  }

  const handleNullValueDisplayGroups = (value: number) => value === -1 ? null : value
  const handleGroupValues = (value: string) => {
    value = value.trim()
    if (value === null || value === '' || isNaN(Number(value))) {
      return -1
    }
    return Number(value)
  }

  if (typeof route.query?.wd_schedule === 'string' && typeof route.query?.wd_groups === 'string') {
    if (!params.displayGroupConfig) {
      params.displayGroupConfig = {}
    }

    params.displayGroupConfig.weekday_schedule_id = Number(route.query.wd_schedule)
    if (params.displayGroupConfig.weekday_schedule_id === -1 || isNaN(params.displayGroupConfig.weekday_schedule_id)) {
      params.displayGroupConfig.weekday_schedule_id = null
    }

    const weekdayDisplayGroups = (route.query.wd_groups as string)?.split(',').map(handleGroupValues) ?? [-1, -1, -1, -1, -1]
    params.displayGroupConfig.weekday_display_group_1_id = handleNullValueDisplayGroups(weekdayDisplayGroups[0])
    params.displayGroupConfig.weekday_display_group_2_id = handleNullValueDisplayGroups(weekdayDisplayGroups[1])
    params.displayGroupConfig.weekday_display_group_3_id = handleNullValueDisplayGroups(weekdayDisplayGroups[2])
    params.displayGroupConfig.weekday_display_group_4_id = handleNullValueDisplayGroups(weekdayDisplayGroups[3])
    params.displayGroupConfig.weekday_display_group_5_id = handleNullValueDisplayGroups(weekdayDisplayGroups[4])
  }

  if (typeof route.query?.we_schedule === 'string' && typeof route.query?.we_groups === 'string') {
    if (!params.displayGroupConfig) {
      params.displayGroupConfig = {}
    }

    params.displayGroupConfig.weekend_schedule_id = Number(route.query.we_schedule)
    if (params.displayGroupConfig.weekend_schedule_id === -1 || isNaN(params.displayGroupConfig.weekend_schedule_id)) {
      params.displayGroupConfig.weekend_schedule_id = null
    }

    const weekendDisplayGroups = (route.query.we_groups as string)?.split(',').map(handleGroupValues) ?? [-1, -1, -1, -1, -1]
    params.displayGroupConfig.weekend_display_group_1_id = handleNullValueDisplayGroups(weekendDisplayGroups[0])
    params.displayGroupConfig.weekend_display_group_2_id = handleNullValueDisplayGroups(weekendDisplayGroups[1])
    params.displayGroupConfig.weekend_display_group_3_id = handleNullValueDisplayGroups(weekendDisplayGroups[2])
    params.displayGroupConfig.weekend_display_group_4_id = handleNullValueDisplayGroups(weekendDisplayGroups[3])
    params.displayGroupConfig.weekend_display_group_5_id = handleNullValueDisplayGroups(weekendDisplayGroups[4])
  }

  return params
}

// displayParamsToUrl converts a DisplayParams object to a url query string.
export function displayParamsToUrl (base: Location, displayParams: DisplayParams): URL {
  const url = new URL(base.href)

  url.search  = ''

  if (displayParams.sections && displayParams.sections.length > 0) {
    url.searchParams.set("sections", displayParams.sections.join(','))
  }

  if (displayParams.ignoreSectionsTimeStart && displayParams.ignoreSectionsTimeEnd) {
    url.searchParams.set("ignore_sections_time", `${displayParams.ignoreSectionsTimeStart}-${displayParams.ignoreSectionsTimeEnd}`)
  }

  if (displayParams.fullscreen) {
    url.searchParams.set("fullscreen", "1")
  }

  if (displayParams.layout && displayParams.layout !== 'grid') {
    url.searchParams.set("layout", displayParams.layout)
  }

  if (displayParams.videoStream) {
    url.searchParams.set("videostream", "1")
  }

  if (displayParams.ignoreSectionsFilterForCallTypes && displayParams.ignoreSectionsFilterForCallTypes.length > 0) {
    url.searchParams.set("ignore_sections_filter_for_call_types", displayParams.ignoreSectionsFilterForCallTypes.join(','))
  }

  if (displayParams.system) {
    url.searchParams.set("system", displayParams.system)
  }

  if (displayParams.locale && displayParams.locale !== Locale.De) {
    url.searchParams.set("locale", displayParams.locale.toLocaleLowerCase())
  }

  if (displayParams.fontSize && displayParams.fontSize !== 'normal') {
    url.searchParams.set("fontsize", displayParams.fontSize)
  }

  if (typeof displayParams.displayGroupConfig === 'object' && Object.keys(displayParams.displayGroupConfig).length > 0) {
    url.searchParams.set("wd_schedule", displayParams.displayGroupConfig.weekday_schedule_id?.toString() ?? "-1")
    url.searchParams.set("we_schedule", displayParams.displayGroupConfig.weekend_schedule_id?.toString() ?? "-1")

    const convertDisplayGroup = (value: number|null|undefined) => {
      if (value === undefined || value === null) {
        return -1
      }
      return value
    }

    const weekdayDisplayGroups = [
      displayParams.displayGroupConfig.weekday_display_group_1_id,
      displayParams.displayGroupConfig.weekday_display_group_2_id,
      displayParams.displayGroupConfig.weekday_display_group_3_id,
      displayParams.displayGroupConfig.weekday_display_group_4_id,
      displayParams.displayGroupConfig.weekday_display_group_5_id
    ].map(convertDisplayGroup)

    const weekendDisplayGroups = [
      displayParams.displayGroupConfig.weekend_display_group_1_id,
      displayParams.displayGroupConfig.weekend_display_group_2_id,
      displayParams.displayGroupConfig.weekend_display_group_3_id,
      displayParams.displayGroupConfig.weekend_display_group_4_id,
      displayParams.displayGroupConfig.weekend_display_group_5_id
    ].map(convertDisplayGroup)

    if (weekdayDisplayGroups.length > 0) {
      url.searchParams.set("wd_groups", weekdayDisplayGroups.join(','))
    }

    if (weekendDisplayGroups.length > 0) {
      url.searchParams.set("we_groups", weekendDisplayGroups.join(','))
    }
  }

  return url
}