<template>
  <div
    class="relative"
    :class="{'overflow-hidden': enabled && !isRevealed}"
    :style="{'max-height': enabled && !isRevealed ? `${maxHeight}px` : ''}"
  >
    <slot />
    <div v-if="enabled && !isRevealed" class="fadeout bg-gradient-to-b from-transparent to-white absolute inset-0 mt-5" @click.prevent="reveal" />
    <div
      v-if="(toggle || !isRevealed) && enabled"
      class="bottom-0 w-full flex justify-center"
      :class="{'-mt-12 absolute': enabled && !isRevealed, 'relative mt-6': isRevealed}"
      @click.prevent="reveal"
    >
      <a
        href="#"
        class="border border-gray-300 bg-white px-4 py-2 rounded mx-auto inline-block text-center color-cs-blue-500 text-xs relative z-10"
        :class="{'mt-4': isRevealed}"
      >
        {{ $t(isRevealed ? 'common.actions.show_less' : 'common.actions.show_more') }}
      </a>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, ref } from 'vue'

  export default defineComponent({
    name: 'FadeoutReveal',
    props: {
      enabled: {
        type: Boolean,
        default: true,
      },
      maxHeight: {
        type: Number,
        default: 200,
      },
      toggle: {
        type: Boolean,
        default: false,
      }
    },
    emits: ['reveal'],
    setup (props, { emit }) {
      const isRevealed = ref(false)

      function reveal () {
        isRevealed.value = !isRevealed.value
        emit('reveal', isRevealed.value)
      }

      return {
        isRevealed,
        reveal,
      }
    }
  })
</script>
